import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Image from "../components/imageFromStarterPleaseStartHere"
//import Contact from "../components/contact"
import axios from "axios"

export default () => {
  const [apiMessage, setApiMessage] = useState("Loading.....")
  // function get() {
  //   axios
  //     .get(`https://dog.ceo/api/breeds/image/random`)
  //     .then(function(response) {
  //       // handle success
  //       console.log("response")
  //       console.log(response)
  //       setApiMessage(response.data.message)
  //     })
  //     .catch(function(error) {
  //       // handle error
  //       console.log("error")
  //       console.log(error)
  //     })
  //     .finally(function() {
  //       // always executed
  //       console.log("finally")
  //     })
  // }
  async function get() {
    axios
      .get(`https://dog.ceo/api/breeds/image/random`)
      .then(function(response) {
        // handle success
        console.log("response async")
        console.log(response)
        setApiMessage(response.data.message)
      })
      .catch(function(error) {
        // handle error
        console.log("error")
        console.log(error)
      })
      .finally(function() {
        // always executed
        console.log("finally async")
      })
  }


  // async function get() {
  //   try {
  //     const response = await axios.get(`https://dog.ceo/api/breeds/image/random`);
  //     console.log(response);console.log(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  return (
    <Layout>
      <SEO title="Contact" />
      <Image />
      <p>{apiMessage}</p>
      <button
        onClick={event => {
          get()
        }}
      >
        Submit
      </button>
    </Layout>
  )
}
